import React, { useContext, useEffect, useState } from "react";
import auth from "../services/authServices";
import http from "../services/httpServices";
import LoadingIcon from "../components/ui/LoadingIcon";
import CenterIcon from "../components/ui/CenterIcon";
import CommunityCategoryCard from "./CommunityCategoryCard";
import AuthContext from "../context/AuthContext";

import AdminIcon from "../assets/Updated-imagery/adminIcon.png";
import Decore from "../assets/Updated-imagery/communityDecore.png";
import BgInkPattern from "../assets/Updated-imagery/inkpattern.png";
const CommunityCategories = ({ type = "card" }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [categories, setCategories] = useState([]);

    const { adminToken, token } = useContext(AuthContext);

    const getCategories = async () => {
        let config = {};
        if (adminToken) {
            config = auth.getAuthConfig(adminToken);
        } else {
            const token = auth.getToken();
            config = auth.getAuthConfig(token);
        }

        // const { data } = http.get("/");
        try {
            const { data } = await http.get(
                `/api/${adminToken ? "admin/" : ""}visible/community_categories`,
                config
            );
            setCategories({});
            setCategories(data);
            setIsLoading(false);
        } catch (error) {
            setIsError(true);
        }
    };
    useEffect(() => {
        getCategories();
    }, []);
    return (
        <>
            {isLoading ? (
                <div className="flex-center-both py-10">
                    <div
                        className={`${
                            isError ? "bg-rose-500" : "bg-cyan-500"
                        } rounded-md py-10 px-5 flex-center-both space-x-3 space-x-reverse font-h3 text-slate-50`}
                    >
                        {isError ? (
                            <>
                                <CenterIcon icon="ic:twotone-error" className="font-h1 pt-1" />
                                <span>حدث خطأ</span>
                            </>
                        ) : (
                            <>
                                <LoadingIcon className="font-h1" />
                                <span>يتم الآن تحميل المجموعات...</span>
                            </>
                        )}
                    </div>
                </div>
            ) : categories.length < 1 ? (
                <div className="flex-center-both py-10">
                    <div
                        className={`bg-orange-600 rounded-md py-10 px-5 flex-center-both space-x-3 space-x-reverse font-h3 text-slate-50`}
                    >
                        <>
                            <CenterIcon icon="carbon:error" className="font-h1 pt-1" />
                            <span>سيتم اضافة المجموعات قريبًا</span>
                        </>
                    </div>
                </div>
            ) : (
                <div className="flex-center-both flex-col space-y-10 py-5">
                    {adminToken ? (
                        <div className="flex-center-both relative overflow-hidden flex-col-reverse gap-5 rounded-lg bg-yellow-100/30 dark:bg-yellow-900/30 border-[1px] border-solid border-black smooth p-10 font-h2 font-w-bold">
                            <img
                                src={BgInkPattern}
                                alt="BgInkPattern"
                                className="absolute inset-0  opacity-20"
                            />
                            <div className="z-10 clr-text-primary smooth">
                                هذه هي المجموعات المتاحة لحساب المسئول الخاص بك
                            </div>
                            <img src={AdminIcon} alt="AdminIconCommunity" className="w-20 h-auto" />
                        </div>
                    ) : (
                        ""
                    )}
                    {}
                    <div className="flex-center-both flex-col space-y-10 pt-5">
                        <div className="relative">
                            <h1 className="z-10 relative text-center font-Jezera font-w-bold w-full text-3xl md:text-4xl lg:text-5xl ">
                                أختار <span className="text-violet-500">مجموعتك</span>
                            </h1>
                        </div>
                    </div>
                    <div
                        className={`${"flex items-center overflow-hidden relative justify-evenly flex-wrap py-10 px-0 md:px-10 gap-8 "}`}
                    >
                        <img
                            src={Decore}
                            alt="Decore#community"
                            className=" absolute -left-[20%] z-0 -top-[10%] opacity-50 max-w-[200px]"
                        />
                        {categories.map((category) => (
                            <CommunityCategoryCard category={category} />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default CommunityCategories;
